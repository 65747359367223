<template>
      <!-- 실제 본문 시작 -->
      <div class="cont-body-wrap">
        <div class="top-head-box st2 st-mb-none">
          <div class="com-inner-box-st1">
            <div class="btn-box"><a href="javascript:;" class="btn-back" @click="$emit('changeSt','register')">이전페이지</a></div>
          </div>
        </div>
        <div class="inner-body-wrap st-full">
          <div class="gift-dtl-slide">
            <slider ref="slider" class="swiper-wrapper" style="width:100%" :options="options">
<!--          <slideritem v-for="(gift,i) in testImages"-->
              <slideritem v-for="(gift,i) in productDetail.productImages"
                          class="swiper-slide"
                          :key="`mainUrls-${i}`">
                <div class="img-box">
                  <img :src="`/attachment/product/${gift.fileServerName}`" alt="">
                </div>
<!--                <div class="img-box" :style="`/attachment/product/${gift.fileServerName}) center top no-repeat;background-size: cover;`"></div>-->
<!--                <img src="../../assets/front/images/img-history-1999.png"/>-->
              </slideritem>
              <div class="swiper-pagination"></div>
            </slider>
          </div>
          <div class="layout-btn-fixed st-none">
            <div class="gift-dtl-box">
              <div class="tit-box">
                <div class="com-inner-box-st1">
                  <div class="txt1">{{ productDetail.productName }}</div>
                  <!--<div class="txt2" v-html="productDetail.productDetail"></div>-->
                </div>
              </div>
              <div class="dtl-line-box"></div>
              <div class="exp-box">
                <div class="com-inner-box-st1">
                  <div class="txt1">상품 설명</div>
                  <div class="txt2" v-html="productDetail.productDetail"></div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
      <!-- 실제 본문 종료 -->

  <!-- id : contents : (E) -->
</template>

<style>
.swiper-container-horizontal>.slider-pagination-bullets{
  bottom: 85px !important;
}
.swiper-container-horizontal>*>.slider-pagination-bullet{
  background:rgba(255, 255, 255) !important;
  opacity:.6 !important;
  margin: 0 2px !important;
}
.swiper-container-horizontal>*>.slider-pagination-bullet-active{
  opacity:1 !important;
  border-radius: 4px !important;
  width: 19px !important;
}
.slider-item{
  text-align: none !important;
  font-size: 16px !important;
  text-align: unset !important;
  justify-content: unset !important;
}
.slider-container{
  overflow: unset !important;
}
</style>
<script>
import PresentApi from "@/api/PresentApi";
import {slider, slideritem} from 'vue-concise-slider'

export default {
  name: "ProductDetail",
  components:{
    slideritem,
    slider
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false,
    },
    productInfo:{}
  },
  data(){
    return {
      presentId: this.$route.params.id,
      productDetail:{},
      // testImages:[{img:"https://img1.daumcdn.net/thumb/R720x0.q80/?scode=mtistory2&fname=http%3A%2F%2Fcfile7.uf.tistory.com%2Fimage%2F24283C3858F778CA2EFABE"},
      //               {img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsPQ18p3_L68_PWgZnJHkP1-TNrRl-gUCCtw&usqp=CAU"}],

      options:{
        pagination: true,
        loop:false,
      },
    }
  },
  computed:{
  },
  created(){
    PresentApi.getProduct(this.productInfo.productId, this.productInfo.authNo, this.productInfo.mobileNo).then(data=>{
      this.productDetail = data;
    })
  },
  destroyed() { 
 //   console.info('destroyed');
  },
  watch:{

  },
  methods:{
  },
}
 
</script>

<style scoped>

</style>