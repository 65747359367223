<template>
  <!-- 실제 본문 시작 -->
  <div class="cont-body-wrap">

    <VueDaumPostcode class="address-pop-layer"/>
    <ProductDetail v-if="pageStatus=='detail'" :productInfo="productInfo" @changeSt="changeStatus"></ProductDetail>
    <MyOrderHistory v-if="pageStatus=='history'" :memberInfo="memberInfo" :isHistoryPage="isHistoryPage" @changeSt="changeStatus"></MyOrderHistory>

    <div v-if="pageStatus=='register'">
      <div class="top-head-box">
        <div class="com-inner-box-st1">
          <h2 class="h2" @click="pageStatus='history'">내 신청내역</h2>
        </div>
      </div>
      <div class="inner-body-wrap st-full">
        <div class="layout-btn-fixed st-none">
          <div class="com-frm-box">
              <div class="com-inner-box-st1">
                <div class="gift-tit-box">{{this.present.title}}</div>
                <div class="gift-item-box" :class="{on:gift==product}" v-for="(gift, i) in giftList" :key="i">
                  <div class="img-box">
                    <!--<img src="/attachment/product/thumb_d284d2bc-b56b-47be-b37e-71a2cb37d041" alt="">-->
                    <!--{{gift.product.productImages[0] != null? gift.product.productImages[0].fileServerName : ""}}-->
                    <img :src="gift.product.productImages[0] ? `/attachment/product/${gift.product.productImages[0].fileServerName}` : ''"/>

                  </div>
                  <div class="mid-box">
                    <div class="radio-box">
                      <input type="radio" :id="'ra0101'+i" :value="gift" v-model="product" class="ra-st1"><label :for="'ra0101'+i">선택</label>
                    </div>
                    <div class="name-box">
                      <span>{{gift.product.productName}}</span>
                      <em style="overflow: hidden;max-width: 80%;" class="ellipsis">{{convertHTML(gift.product.productDetail)}}</em>
                    </div>
                    <a href="javascript:;" class="btn-more" @click="onClickProductDetail(gift.product.productId)">상세</a>
                  </div>
                </div>
              </div>

              <div class="gift-opt-box">
                <div class="opt-tit-box">
                  <div class="com-inner-box-st1">
                    <span class="tit">선택 항목</span><span class="num">-</span>
                  </div>
                </div>
                <div class="com-inner-box-st1">
                  <div class="tit2">배송지 정보</div>
                  <div class="address-choice-box">
                    <div class=""><input type="radio" id="ra0201" value="my" name="address-typ" class="ra-st1 st-fnt" @click="onClickAddressNew(false)"><label for="ra0201">내 주소 사용</label></div>
                    <div class=""><input type="radio" id="ra0202" value="new" checked name="address-typ" class="ra-st1 st-fnt" @click="onClickAddressNew(true)"><label for="ra0202">새 주소 입력</label></div>
                  </div>
                  <div class="my-address address-input-box" :class="{open:!addressNew}">
                    <div class="txt1">배송지 정보</div>
                    <div class="txt2"><span>{{ memberInfo.name }}</span> <em>{{ memberInfo.mobileNo }}</em></div>
                    <div class="txt3">({{ memberInfo.zipCode }}) {{ memberInfo.address + " " + memberInfo.addressDetail }}</div>
                  </div>
                  <div class="new-address address-input-box" :class="{open:addressNew}">
                    <div class="input-box st-wide">
                      <div class="tits">받으시는 분</div>
                      <input type="text" class="st-input" placeholder="받으시는분 성함을 입력해주세요." v-model="order.name">
                    </div>
                    <div class="input-box st-wide">
                      <div class="tits">휴대폰 번호</div>
                      <input type="text" class="st-input" placeholder="“-” 없이 입력해주세요." v-model="order.mobileNo" @input="onInputMobile">
                    </div>
                    <div class="input-box st-address">
                      <div class="tits">주소</div>
                      <div class="post-box">
                        <input type="text" class="st-input" v-model="order.zipCode" @click="openZipCode">
                        <a href="javascript:;" class="" @click="openZipCode">주소검색</a>
                      </div>
                      <input type="text" class="st-input" v-model="order.address" @click="openZipCode">
                      <input type="text" class="st-input mt5" placeholder="상세주소를 입력해주세요." v-model="order.addressDetail">
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="com-inner-box-st1">
            <div class="com-btn-box"><a href="javascript:;" class="btnFinishMsg" @click="onClickRegister">신청하기</a></div>
            <!-- 하단에 스크립트 있음 : btnFinishMsg -->
          </div>

        </div>

      </div>
      <!-- 신청 확인 메세지 -->
      <div class="dg-pop-layer pop-wd-mid st-one-msg on" id="popFinishMsgBox" v-if="popup1">
        <div class="pop-cont-box ">
          <div class="message-box">입력하신 주소로 <br>선택하신 선물이 배송됩니다.</div>
          <div class="com-btn-box st2"><a href="javascript:;" class="dg-pop-close" @click="onClickPopup1(false)">취소</a><a href="javascript:;" class="" @click="onClickPopup1Next">확인</a></div>
        </div>
      </div>
      <!-- 신청 확인 메세지 -->

      <!-- 신청 완료 메세지 -->
      <div class="dg-pop-layer pop-wd-mid st-one-msg on" id="popFinishMsg2Box" v-if="popup2">
        <div class="pop-cont-box ">
          <div class="message-box">선물 신청이 완료되었습니다.</div>
          <div class="com-btn-box"><a href="javascript:;" class="" @click="onClickPopup2(false)">확인</a></div>
        </div>
      </div>
      <!-- 신청 완료 메세지 -->

      <!-- 오류 메세지 -->
      <div class="dg-pop-layer pop-wd-mid st-one-msg on" id="popFinishMsg3Box" v-if="popup3">
        <div class="pop-cont-box">
          <div class="message-box">{{ errorMsg }}</div>
          <div class="com-btn-box"><a href="javascript:;" class="" @click="onClickPopup3(false)">확인</a></div>
        </div>
      </div>
      <!-- 오류 메세지 -->

    </div>
    <!-- 실제 본문 종료 -->
  </div>
  <!-- id : contents : (E) -->
</template>

<style>
.swiper-container-horizontal>.slider-pagination-bullets{
  bottom: 15px !important;
}
</style>
<script>
import PresentApi from "@/api/PresentApi";
import VueDaumPostcode from "@/views/component/vue-daum-postcode"
import ProductDetail from "@/views/gift/detail"
import MyOrderHistory from "@/views/gift/history"

export default {
  name: "Register",
  components:{
    VueDaumPostcode,
    ProductDetail,
    MyOrderHistory
  },
  props:{
    isOpen:{
      type:Boolean,
      default:false,
    },
  },
  watch:{
    isOpen(e){
      if(!e){
        this.popup1=false
        this.popup3=false
      }
    }
  },
  data(){
    return {
      presentId: this.$route.params.id,
      addressNew: true,
      giftList: null,

      popup1:false,
      popup2:false,
      popup3:false,
      errorMsg:"",

      product: null,
      memberInfo:this.$route.params.memberInfo,

      order:{
        orderId:"",
        name:"",
        zipCode:"",
        address:"",
        addressDetail:"",
        mobileNo:""
      },
      present:{

      },

      isSent: false,
      isHistoryPage: false,

      pageStatus: "register",
      productInfo: false
    }
  },
  computed:{
  },
  created(){
    PresentApi.productList(this.memberInfo.authNo, this.memberInfo.mobileNo, this.presentId).then(data=>{
      this.giftList = data;
      this.product = this.giftList[0];
    });

    PresentApi.getOrder(this.presentId, this.memberInfo.userId, this.memberInfo.authNo, this.memberInfo.mobileNo).then(data=>{
      this.order.orderId = data.orderId;
      this.present = data.present;
    })
  },
  methods:{
    onClickPopup1(e){
      this.popup1 = e
      this.$emit('click:bgDim', this.popup1)
    },
    onClickPopup1Next(){
      let params = {...this.order, ...{
          authNo: this.memberInfo.authNo,
          authMobileNo: this.memberInfo.mobileNo,
          userId: this.memberInfo.userId,
          presentId: this.presentId,
          presentProductId: this.product.presentProductId,
          productId: this.product.product.productId
        }};

      PresentApi.save(params).then(()=>{
        this.popup1 = false;
        this.popup2 = true;
      }).catch(()=>{
        alert('신청 중 오류가 발생했습니다.');
      })
    },
    onClickPopup2(e){
      this.popup2 = e;
      this.$emit('click:bgDim', this.popup2)
      this.pageStatus = 'history';
      this.isHistoryPage = true;
    },
    onClickPopup3(e){
      this.popup3 = e
      this.$emit('click:bgDim', this.popup3)
    },

    onInputMobile(e){
      this.isSent=false;
      this.order.mobileNo = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    },
    onClickAddressNew(e){
      this.addressNew = e;

      if(e){
        this.order = {
          address : "",
          addressDetail : "",
          zipCode : "",
          name : "",
          mobileNo : "",
        };
      }
    },
    onClickRegister(){
      if(!this.addressNew){
        this.order.address = this.memberInfo.address;
        this.order.addressDetail = this.memberInfo.addressDetail;
        this.order.zipCode = this.memberInfo.zipCode;
        this.order.name = this.memberInfo.name;
        this.order.mobileNo = this.memberInfo.mobileNo;
      }

      if(this.order.address == "" || this.order.addressDetail == "" || this.order.zipCode == "" ){
        this.errorMsg = "주소를 입력해주세요.";
        this.onClickPopup3(true);
      }else if(this.order.name == "" || this.order.mobileNo == ""){
        this.errorMsg = "받으시는 분 정보를 입력해주세요.";
        this.onClickPopup3(true);
      }else {
        this.onClickPopup1(true);
      }
    },
    openZipCode() {
      let _order = this.order;
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: function(data) {
          _order.zipCode = data.zonecode;
          _order.address = data.address;
        }}).open();
    },
    onClickProductDetail(val){
      this.pageStatus = "detail";
      let param = {
        productId : val,
        authNo : this.memberInfo.authNo,
        mobileNo : this.memberInfo.mobileNo
      };
      this.productInfo = param;

      //this.$router.push({name:'detail', params:{propsInfo:param}});
    },
    changeStatus(e){
      this.pageStatus=e;
    },
    convertHTML(val){
      return val.replace(/&nbsp;/gi, '').replace(/(<([^>]+)>)/ig,"");
    }
  },
}
 
</script>

<style scoped>

</style>